import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;
export default {
  getComboPercentages: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${apiURL}/admin/getComboPercentages`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
