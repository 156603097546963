<template>
  <v-main>
    <v-card class="modalSystem mx-auto" max-width="85%">
      <h3 style="text-align: center">FICHA DE PROYECTO</h3>
      <v-row class="mt-6">
        <v-col md="3">
          <v-text-field
            label="Código de proyecto"
            outlined
            readonly
            size="40"
            v-model="projectFile.projectCode"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field label="Nombre proyecto" outlined readonly size="40" v-model="projectFile.name"></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Responsable"
            outlined
            readonly
            size="40"
            v-model="projectFile.responsible"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Especialista"
            outlined
            readonly
            size="40"
            v-model="projectFile.specialist"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Brecha a acortar"
            outlined
            readonly
            size="40"
            v-model="projectFile.breach"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Objetivos de negocio"
            outlined
            readonly
            size="40"
            v-model="projectFile.businessObjective"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Alcance del proyecto"
            outlined
            readonly
            size="40"
            v-model="projectFile.reach"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Criterios de evaluación"
            outlined
            readonly
            size="40"
            v-model="projectFile.evalCriteria"
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-text-field
            label="Objetivos del proyecto"
            outlined
            readonly
            size="40"
            v-model="projectFile.projectObjective"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field label="Dimensión que impacta" outlined size="40" v-model="projectFile.dimension"></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Componente a impactar"
            outlined
            readonly
            size="40"
            v-model="projectFile.component"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Mejora cualitativa"
            outlined
            readonly
            size="40"
            v-model="projectFile.qualitativeImprov"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Mejora cuantitativa"
            outlined
            readonly
            size="40"
            v-model="projectFile.quantitativeImprov"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            label="Presupuesto estimado"
            outlined
            readonly
            size="40"
            v-model="projectFile.estimatedBudget"
          ></v-text-field>
        </v-col>
        <!-- <v-col md="12" class="text-center">
          <v-btn
            class="mx-auto button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
            outlined
            color="indigo lighten-2"
            @click="saveProjectFunc"
          >
            <v-icon color="purple"> mdi-content-save </v-icon>
            Guardar Ficha
          </v-btn>
        </v-col> -->
        <v-spacer></v-spacer>
        <div class="container">
          <h3 class="mt-4 text-center">EQUIPO</h3>
          <form>
            <v-row class="mt-6">
              <v-col md="3">
                <v-text-field type="text" label="Nombre" v-model="teamItem.name" outlined />
              </v-col>
              <v-col md="2">
                <v-text-field type="text" label="Rol" v-model="teamItem.role" outlined />
              </v-col>
              <v-col md="3">
                <v-text-field type="text" label="Correo" v-model="teamItem.email" outlined />
              </v-col>
              <v-col md="2">
                <v-text-field type="text" label="Teléfono" v-model="teamItem.phone" outlined />
              </v-col>
              <v-col md="2">
                <v-select
                  :items="combos.percentage"
                  item-value="id"
                  item-text="name"
                  label="Dedicación"
                  v-model="teamItem.dedicacionId"
                  outlined
                ></v-select>
              </v-col>
              <v-col md="12" class="text-center">
                <v-btn
                  class="mx-auto button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                  outlined
                  color="indigo lighten-2"
                  @click="saveTeamFunc()"
                >
                  <v-icon color="purple"> mdi-content-save </v-icon>
                  Guardar
                </v-btn>
              </v-col>
              <v-alert
                v-model="alert.saveResponsible"
                border="right"
                colored-border
                type="error"
                elevation="2"
                class="ml-2 mr-2"
              >
                {{ messageError.saveResponsible }}
              </v-alert>
              <v-col md="12">
                <v-simple-table class="table mt-5">
                  <template>
                    <thead>
                      <tr>
                        <th class="text-left" scope="col">#No</th>
                        <th class="text-left" scope="col">Nombre</th>
                        <th class="text-left" scope="col">Rol</th>
                        <th class="text-left" scope="col">Correo</th>
                        <th class="text-left" scope="col">Teléfono</th>
                        <th class="text-left" scope="col">Dedicación</th>
                        <th class="text-left" scope="col">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(entry, i) in team" :key="i">
                        <th scope="row">{{ ++i }}</th>
                        <td>{{ entry.name }}</td>
                        <td>{{ entry.role }}</td>
                        <td>{{ entry.email }}</td>
                        <td>{{ entry.phone }}</td>
                        <td>{{ entry.dedicationName }}</td>
                        <td>
                          <v-icon small color="error" @click="deleteTeam(entry)"> delete </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </form>
        </div>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import projectService from "../../services/pmo/project/projectService";
import adminService from "../../services/pmo/admin/adminService";
import CallForTenderService from "../../services/callForTenderService";

export default {
  name: "Leaderboard",
  data: () => ({
    codeProject: "",
    nameProject: "",
    responsibleCompany: "",
    specialist: "",
    gap: "",
    objectiveBusiness: "",
    scopeProject: "",
    criterionEvaluation: "",
    objectiveProject: "",
    dimensionImpact: "",
    componentImpact: "",
    betterCualitative: "",
    betterQuantitative: "",
    committedDeliverable: "",
    name: "",
    score: "",
    correo: "",
    dedicacion: "",
    allScores: [],
    items: ["10%", "25%", "50%", "75%", "100%"],
    projectFile: [],
    teamItem: {
      name: "",
      role: "",
      email: "",
      phone: "",
      dedicacionName: "",
      dedicacionId: "",
    },
    team: [],
    combos: {
      percentage: [],
    },
    alert: {
      saveResponsible: false,
    },
    messageError: {
      saveResponsible: "",
    },
  }),
  computed: {
    sortedList: function () {
      return this.allScores.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
  },
  methods: {
    clearForm() {},
    saveFile() {
      this.allScores.push({
        name: this.name,
        score: this.score,
        correo: this.correo,
        dedicacion: this.dedicacion,
      });
      this.clearFormFile();
    },
    saveTeam() {
      this.allScores.push({
        codeProject: this.codeProject,
        nameProject: this.nameProject,
        responsibleCompany: this.responsibleCompany,
        specialist: this.specialist,
        gap: this.gap,
        objectiveBusiness: this.objectiveBusiness,
        scopeProject: this.scopeProject,
        criterionEvaluation: this.criterionEvaluation,
        objectiveProject: this.objectiveProject,
        dimensionImpact: this.dimensionImpact,
        componentImpact: this.componentImpact,
        betterCualitative: this.betterCualitative,
        betterQuantitative: this.betterQuantitative,
        committedDeliverable: this.committedDeliverable,
      });
      this.clearFormTeam();
    },
    clearFormFile() {
      this.teamItem.name = "";
      this.teamItem.role = "";
      this.teamItem.email = "";
      this.teamItem.phone = "";
      this.teamItem.dedicacionId = "";
    },
    clearFormTeam() {
      this.codeProject = "";
      this.nameProject = "";
      this.responsibleCompany = "";
      this.specialist = "";
      this.gap = "";
      this.objectiveBusiness = "";
      this.scopeProject = "";
      this.criterionEvaluation = "";
      this.objectiveProject = "";
      this.dimensionImpact = "";
      this.componentImpact = "";
      this.betterCualitative = "";
      this.betterQuantitative = "";
      this.committedDeliverable = "";
    },
    deleteItem(entry) {
      const index = this.allScores.indexOf(entry);
      confirm("Are you sure you want to delete this item?") && this.allScores.splice(index, 1);
    },
    getProject() {
      projectService
        .getProject(this.project)
        .then((data) => {
          this.projectFile = data[0];
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveResponsible = false), 4000);
        });
    },
    saveProjectFunc() {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        name: this.projectFile.name,
        projectCode: this.projectFile.projectCode,
        breach: this.projectFile.breach,
        businessObjective: this.projectFile.businessObjective,
        reach: this.projectFile.reach,
        projectObjective: this.projectFile.projectObjective,
        evalCriteria: this.projectFile.evalCriteria,
        dimension: this.projectFile.dimension,
        component: this.projectFile.component,
        qualitativeImprov: this.projectFile.qualitativeImprov,
        quantitativeImprov: this.projectFile.quantitativeImprov,
        deliverable: this.projectFile.deliverable,
        period: this.projectFile.period,
        budget: this.projectFile.budget,
      };

      projectService
        .saveProject(request)
        .then(() => {
          this.getProject();
          this.clearFormFile();
        })
        .catch((err) => {
          console.dir(err);
          let message = err.response.data.message;
          this.alert.saveResponsible = true;
          this.messageError.saveResponsible = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveResponsible = false), 4000);
        });
    },
    getTeam() {
      projectService
        .getTeam(this.project)
        .then((data) => {
          this.team = data;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveResponsible = false), 4000);
        });
    },
    saveTeamFunc() {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        name: this.teamItem.name,
        role: this.teamItem.role,
        email: this.teamItem.email,
        phone: this.teamItem.phone,
        dedicationId: this.teamItem.dedicacionId,
      };

      projectService
        .saveTeam(request)
        .then(() => {
          this.getTeam();
          this.clearFormFile();
        })
        .catch((err) => {
          console.dir(err);
          let message = err.response.data.message;
          this.alert.saveResponsible = true;
          this.messageError.saveResponsible = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveResponsible = false), 4000);
        });
    },
    deleteTeam(entry) {
      projectService
        .deleteTeam(entry.idResponsible)
        .then(() => {
          this.getTeam();
          console.log("Team: ", this.team);
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          setTimeout(() => (this.alert.saveResponsible = false), 4000);
        });
    },
    getProjectInfor() {
      CallForTenderService.getProject()
        .then((data) => console.log("dato proyectos: ", data))
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getProject();
    this.getTeam();
    adminService
      .getComboPercentages()
      .then((data) => {
        this.combos.percentage = data;
      })
      .catch((err) => {
        console.dir(err);
      })
      .finally(() => {
        setTimeout(() => (this.alert.saveStage = false), 4000);
      });
    this.getProjectInfor();
  },
};
</script>
<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
  box-shadow: none !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.modalSystem .v-text-field__details {
  display: none;
}
</style>
